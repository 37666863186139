import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
// Components
import ContentComponent from "../components/ContentComponent";
import HeroSection from "../components/HeroSection";
import BlogCategoriesNav from "../components/BlogCategoriesNav";
import FeaturedPost from "../components/FeaturedPost";
import Seo from "../components/Seo";

import FaqComponent from "../components/FaqComponent";
import HowToComponent from "../components/HowToComponent";
import CasinosList from "../components/CasinosList";
import CtaComponent from "../components/CallToActionComponents";
import FeaturedPosts from "../components/FeaturedPosts";
import GameOfTheMonth from "../components/GameOfTheMonth";
import BlogCardsListing from "../components/BlogCardsListing";

const BogListingPage = ({ data }) => {
  const pageData = data?.pageData;
  const allPosts = data?.allPosts?.edges;
  const featuredPost = data?.featuredPost.edges[0];
  const categoriesPosts = data?.categoriesPosts?.edges;
  const components = pageData?.components;

  const categorySet = new Set();

  categoriesPosts.forEach((post) => {
    post.node.categories[0] && categorySet.add(post.node.categories[0]);
  });
  const categoryList = Array.from(categorySet);

  return (
    <Layout>
      <div className="content container" style={{ minHeight: "700px" }}>
        {categoryList && <BlogCategoriesNav categories={categoryList} />}
        <FeaturedPost
          title={featuredPost?.node?.title}
          createdDate={featuredPost?.node?.createdData}
          featuredImage={featuredPost?.node?.featuredImage}
          categories={featuredPost?.node?.categories}
          slug={featuredPost?.node?.slug}
          excerpt={featuredPost?.node?.cardDescription?.cardDescription}
          cardDescription={featuredPost?.node?.cardDescription.cardDescription}
        />
        <BlogCardsListing cardsList={allPosts} />
        {components?.map((component) => {
          switch (component.__typename) {
            case "ContentfulSeoComponent":
              return (
                <Seo
                  title={component?.seoTitle}
                  description={component?.seoDescription}
                  url={`https://nettikasinolista.com/${component?.seoSlug}/`}
                  canonical={`https://nettikasinolista.com/${component?.seoSlug}/`}
                />
              );
            case "ContentfulHeroComponent":
              return (
                <HeroSection
                  title={component?.title}
                  casinosCardsData={component?.topCasinos}
                  backgroundColor={component?.backgroundColor}
                />
              );
            case "ContentfulCallToAction":
              return <CtaComponent title={component?.title} ctaCards={component?.callToActionCards} />;
            case "ContentfulGameOfTheMonth":
              return (
                <GameOfTheMonth
                  gameName={component?.gameName}
                  gameImage={component?.gameImage}
                  gameDescription={component?.gameDescription?.childMarkdownRemark?.html}
                  playGameLink={component?.playGameLink}
                  link={component?.link}
                />
              );
            case "ContentfulFeaturedPosts":
              return <FeaturedPosts title={component?.title} postCards={component?.relatedPosts} />;
            case "ContentfulRelatedCasinosList":
              return <CasinosList title={component?.title} casinosCardsData={component?.casinosList} />;
            case "ContentfulHowTo":
              return (
                <HowToComponent
                  title={component?.title}
                  description={component?.description}
                  duration={component?.duration}
                  estimatedCost={component?.estimatedCost}
                  howToCards={component?.howToCards}
                />
              );
            case "ContentfulContentComponent":
              return <ContentComponent data={component?.contentText?.childMarkdownRemark?.html} />;
            case "ContentfulFaqComponent":
              return <FaqComponent faqItems={component?.faqItems} title={component?.title} />;
            default:
              return <></>;
          }
        })}
      </div>
    </Layout>
  );
};

export default BogListingPage;

export const query = graphql`
  query blogListingPage($id: String!, $category: String!) {
    categoriesPosts: allContentfulBlogPost {
      edges {
        node {
          id
          slug
          categories
        }
      }
    }
    featuredPost: allContentfulBlogPost(
      limit: 1
      filter: { categories: { eq: $category } }
      sort: { fields: createdData, order: DESC }
    ) {
      edges {
        node {
          categories
          cardDescription {
            cardDescription
          }
          createdData(formatString: "DD.MM.YYYY")
          title
          slug
          cardDescription {
            cardDescription
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {
            gatsbyImage(width: 900, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
          }
        }
      }
    }
    allPosts: allContentfulBlogPost(
      filter: { categories: { eq: $category } }
      sort: { fields: createdData, order: DESC }
    ) {
      edges {
        node {
          categories
          createdData(formatString: "DD.MM.YYYY")
          title
          slug
          featuredImage {
            gatsbyImage(width: 300, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
          }
        }
      }
    }
    pageData: contentfulWebsitePage(id: { eq: $id }) {
      id
      pageName
      components {
        ... on ContentfulSeoComponent {
          id
          __typename
          seoSlug
          seoTitle
          seoDescription
        }
        ... on ContentfulCallToAction {
          id
          marginBottom
          marginTop
          __typename
          title
          callToActionCards {
            title
            slug
            icon {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulContentComponent {
          id
          marginBottom
          marginTop
          __typename
          contentText {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFaqComponent {
          id
          __typename
          title
          marginBottom
          marginTop
          faqItems {
            faqAnswer {
              childMarkdownRemark {
                html
              }
            }
            faqQuestion
          }
        }
        ... on ContentfulFeaturedPosts {
          id
          __typename
          title
          marginBottom
          marginTop
          relatedPosts {
            title
            createdData
            categories
            slug
            featuredImage {
              gatsbyImage(width: 300, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
        ... on ContentfulGameOfTheMonth {
          id
          __typename
          gameName
          link
          marginBottom
          marginTop
          gameImage {
            gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
          }
          gameDescription {
            childMarkdownRemark {
              html
            }
          }
          playGameLink {
            linkText
            linkType
            linkRoute
          }
        }
        ... on ContentfulHeroComponent {
          id
          __typename
          title
          backgroundColor
          marginBottom
          marginTop
          topCasinos {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            casinoAward
            awardTextColor
            logo {
              gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
        ... on ContentfulHowTo {
          id
          marginBottom
          marginTop
          __typename
          title
          duration
          estimatedCost
          description {
            id
            childMarkdownRemark {
              html
            }
          }
          howToCards {
            title
            description
            labelText
            labelIndex
            image {
              gatsbyImage(width: 270, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
              file {
                url
              }
            }
          }
        }
        ... on ContentfulRelatedCasinosList {
          id
          marginBottom
          marginTop
          __typename
          title
          casinosList {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            logo {
              gatsbyImage(width: 120, quality: 100, placeholder: NONE, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
