import React from "react";
import { Link } from "gatsby";

const BlogCategoriesNav = ({ categories }) => {
  return (
    <div className="categories-nav">
      {categories.length &&
        categories.map((category) => {
          const catLink = category.toLowerCase();
          return (
            <Link
              to={`/${catLink}/`}
              key={catLink}
              activeStyle={{ color: "#5357CE", borderBottom: "1px solid #5357CE" }}
            >
              {category}
            </Link>
          );
        })}
    </div>
  );
};

export default BlogCategoriesNav;
